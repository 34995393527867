import {
  FETCH_DIVISIO_REQUEST,
  FETCH_DIVISIO_SUCCESS,
  FETCH_DIVISIO_FAIL,
} from './divisionsActionType'

import axios from 'axios'

const fetchDivisioRequest = () => {
  return {
    type: FETCH_DIVISIO_REQUEST,
  }
}

const fetchDivisioSuccess = (divisions) => {
  return {
    type: FETCH_DIVISIO_SUCCESS,
    payload: divisions,
  }
}

const fetchDivisioFail = (error) => {
  return {
    type: FETCH_DIVISIO_FAIL,
    payload: error,
  }
}

export const fetchDivisionsList = () => {
  return (dispatch) => {
    dispatch(fetchDivisioRequest)
    axios
      .get(`${process.env.REACT_APP_API}/divisions`)
      .then((response) => {
        let divisions = response.data
        dispatch(fetchDivisioSuccess(divisions))
      })
      .catch((error) => {
        dispatch(fetchDivisioFail(error.message))
      })
  }
}
