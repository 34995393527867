import {
  FETCH_SANCIONS_REQUEST,
  FETCH_SANCIONS_SUCCESS,
  FETCH_SANCIONS_FAIL,
} from './sancionsActionType'

const initialState = {
  loading: true,
  data: [],
  error: '',
}

export function SancionsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SANCIONS_REQUEST:
      return {
        ...state,
        sancions: action.payload,
        loading: true,
      }
    case FETCH_SANCIONS_SUCCESS:
      return {
        loading: false,
        data: action.payload,
        error: '',
      }
    case FETCH_SANCIONS_FAIL:
      return {
        loading: false,
        data: [],
        error: action.payload,
      }
    default:
      return state
  }
}
