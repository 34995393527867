import {
  FETCH_CLASSIFICACIONS_REQUEST,
  FETCH_CLASSIFICACIONS_SUCCESS,
  FETCH_CLASSIFICACIONS_FAIL,
} from './classificacionsActionType'

const initialState = {
  loading: true,
  data: [],
  error: '',
}

export function ClassificacionsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CLASSIFICACIONS_REQUEST:
      return {
        ...state,
        classificacio: action.payload,
        loading: true,
      }
    case FETCH_CLASSIFICACIONS_SUCCESS:
      return {
        loading: false,
        data: action.payload,
        error: '',
      }
    case FETCH_CLASSIFICACIONS_FAIL:
      return {
        loading: false,
        data: [],
        error: action.payload,
      }
    default:
      return state
  }
}
