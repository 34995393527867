import {
  FETCH_CALENDARI_REQUEST,
  FETCH_CALENDARI_SUCCESS,
  FETCH_CALENDARI_FAIL,
} from './calendariActionType'

import axios from 'axios'

const fetchCalendariRequest = () => {
  return {
    type: FETCH_CALENDARI_REQUEST,
  }
}

const fetchCalendariSuccess = (calendari) => {
  return {
    type: FETCH_CALENDARI_SUCCESS,
    payload: calendari,
  }
}

const fetchCalendariFail = (error) => {
  return {
    type: FETCH_CALENDARI_FAIL,
    payload: error,
  }
}

export const fetchCalendari = () => {
  return (dispatch) => {
    dispatch(fetchCalendariRequest)
    axios
      .get(`${process.env.REACT_APP_API}/calendari`)
      .then((response) => {
        dispatch(fetchCalendariSuccess(response.data))
      })
      .catch((error) => {
        dispatch(fetchCalendariFail(error.message))
      })
  }
}
