import {
  FETCH_CALENDARI_FAIL,
  FETCH_CALENDARI_REQUEST,
  FETCH_CALENDARI_SUCCESS,
} from './calendariActionType'

const initialState = {
  loading: true,
  data: [],
  error: '',
}

export const CalendariReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CALENDARI_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FETCH_CALENDARI_SUCCESS:
      return {
        loading: false,
        data: action.payload,
        error: '',
      }
    case FETCH_CALENDARI_FAIL:
      return {
        loading: false,
        data: [],
        error: action.payload,
      }
    default:
      return state
  }
}
