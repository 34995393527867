import {
  FETCH_OBSERVACIONS_REQUEST,
  FETCH_OBSERVACIONS_SUCCESS,
  FETCH_OBSERVACIONS_FAIL,
} from './observacionsActionType'

import axios from 'axios'

const fetchObservacionsRequest = () => {
  return {
    type: FETCH_OBSERVACIONS_REQUEST,
  }
}

const fetchObservacionsSuccess = (observacions) => {
  return {
    type: FETCH_OBSERVACIONS_SUCCESS,
    payload: observacions,
  }
}

const fetchObservacionsFail = (error) => {
  return {
    type: FETCH_OBSERVACIONS_FAIL,
    payload: error,
  }
}

export const fetchObservacions = () => {
  return (dispatch) => {
    dispatch(fetchObservacionsRequest)
    axios
      .get(`${process.env.REACT_APP_API}/observacions`)
      .then((response) => {
        dispatch(fetchObservacionsSuccess(response.data))
      })
      .catch((error) => {
        dispatch(fetchObservacionsFail(error.message))
      })
  }
}
