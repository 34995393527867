import {
  FETCH_SUSPENSIONS_REQUEST,
  FETCH_SUSPENSIONS_SUCCESS,
  FETCH_SUSPENSIONS_FAIL,
} from './suspensionsActionType'

import axios from 'axios'

const fetchSuspensionsRequest = () => {
  return {
    type: FETCH_SUSPENSIONS_REQUEST,
  }
}

const fetchSuspensionsSuccess = (suspensions) => {
  return {
    type: FETCH_SUSPENSIONS_SUCCESS,
    payload: suspensions,
  }
}

const fetchSuspensionsFail = (error) => {
  return {
    type: FETCH_SUSPENSIONS_FAIL,
    payload: error,
  }
}

export const fetchSuspensions = () => {
  return (dispatch) => {
    dispatch(fetchSuspensionsRequest)
    axios
      .get(`${process.env.REACT_APP_API}/suspensions`)
      .then((response) => {
        dispatch(fetchSuspensionsSuccess(response.data))
      })
      .catch((error) => {
        dispatch(fetchSuspensionsFail(error.message))
      })
  }
}
