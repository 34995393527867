import { FETCH_JUNTA_REQUEST, FETCH_JUNTA_SUCCESS, FETCH_JUNTA_FAIL } from './juntaActionType'

const initialState = {
  loading: true,
  data: [],
  error: '',
}

export function JuntaReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_JUNTA_REQUEST:
      return {
        ...state,
        junta: action.payload,
        loading: true,
      }
    case FETCH_JUNTA_SUCCESS:
      return {
        loading: false,
        data: action.payload,
        error: '',
      }
    case FETCH_JUNTA_FAIL:
      return {
        loading: false,
        data: [],
        error: action.payload,
      }
    default:
      return state
  }
}
