import { FETCH_EQUIP_REQUEST, FETCH_EQUIP_SUCCESS, FETCH_EQUIP_FAIL } from './equipActionType'

const initialState = {
  loading: true,
  data: [],
  error: '',
}

export function EquipReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_EQUIP_REQUEST:
      return {
        ...state,
        equip: action.payload,
        loading: true,
      }
    case FETCH_EQUIP_SUCCESS:
      return {
        loading: false,
        data: action.payload,
        error: '',
      }
    case FETCH_EQUIP_FAIL:
      return {
        loading: false,
        data: [],
        error: action.payload,
      }
    default:
      return state
  }
}
