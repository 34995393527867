import { FETCH_JUNTA_REQUEST, FETCH_JUNTA_SUCCESS, FETCH_JUNTA_FAIL } from './juntaActionType'

import axios from 'axios'

const fetchJuntaRequest = () => {
  return {
    type: FETCH_JUNTA_REQUEST,
  }
}

const fetchJuntaSuccess = (junta) => {
  return {
    type: FETCH_JUNTA_SUCCESS,
    payload: junta,
  }
}

const fetchJuntaFail = (error) => {
  return {
    type: FETCH_JUNTA_FAIL,
    payload: error,
  }
}

export const fetchJunta = (id) => {
  return (dispatch) => {
    dispatch(fetchJuntaRequest)
    axios
      .get(`${process.env.REACT_APP_API}/junta`)
      .then((response) => {
        let junta = response.data
        dispatch(fetchJuntaSuccess(junta))
      })
      .catch((error) => {
        dispatch(fetchJuntaFail(error.message))
      })
  }
}
