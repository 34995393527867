import { configureStore } from '@reduxjs/toolkit'
import { CalendariReducer } from './store/calendari/calendariSlice'
import { DivisionsReducer } from './store/divisions/divisionsSlice'
import { EquipsReducer } from './store/equips/equipsSlice'
import { ClassificacionsReducer } from './store/classificacions/classificacionsSlice'
import { EquipReducer } from './store/equip/equipsSlice'
import { SancionsReducer } from './store/sancions/sancionsSlice'
import { JornadesReducer } from './store/jornades/jornadesSlice'
import { ObservacionsReducer } from './store/observacions/observacionsSlice'
import { SuspensionsReducer } from './store/suspensions/suspensionsSlice'
import { JuntaReducer } from './store/junta/juntaSlice'

const initialState = {
  sidebarShow: true,
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return state
  }
}

const store = configureStore({
  reducer: {
    sidebar: changeState,
    calendari: CalendariReducer,
    divisions: DivisionsReducer,
    equips: EquipsReducer,
    equip: EquipReducer,
    jornades: JornadesReducer,
    junta: JuntaReducer,
    classificacions: ClassificacionsReducer,
    sancions: SancionsReducer,
    suspensions: SuspensionsReducer,
    observacions: ObservacionsReducer,
  },
})

export default store
