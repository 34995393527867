/* eslint-disable react/prop-types */
import React, { Component, Suspense } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import { connect } from 'react-redux'
import { fetchDivisionsList } from './store/divisions/divisionsAction'
import { fetchCalendari } from './store/calendari/calendariAction'
import { fetchObservacions } from './store/observacions/observacionsAction'
import { fetchSuspensions } from './store/suspensions/suspensionsAction'
import { fetchJunta } from './store/junta/juntaAction'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Page404 = React.lazy(() => import('./views/pages/404'))
const Page500 = React.lazy(() => import('./views/pages/500'))

class App extends Component {
  componentDidMount() {
    const { fetchDivisions, fetchCalendari, fetchJunta, fetchObservacions, fetchSuspensions } =
      this.props
    fetchDivisions()
    fetchCalendari()
    fetchJunta()
    fetchObservacions()
    fetchSuspensions()
  }

  render() {
    return (
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route exact path="*" name="Home" element={<DefaultLayout />} />
          </Routes>
        </Suspense>
      </HashRouter>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchDivisions: () => dispatch(fetchDivisionsList()),
    fetchCalendari: () => dispatch(fetchCalendari()),
    fetchJunta: () => dispatch(fetchJunta()),
    fetchObservacions: () => dispatch(fetchObservacions()),
    fetchSuspensions: () => dispatch(fetchSuspensions()),
  }
}

export default connect(null, mapDispatchToProps)(App)
